import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderCloseIconButton } from '../components';

export type HeaderWithCloseProps = Omit<
  BaseHeaderProps,
  'leftElement' | 'rightElement'
> & {
  onCloseClick?: () => void;
};

export function HeaderWithClose({
  onCloseClick,
  ...props
}: Readonly<HeaderWithCloseProps>) {
  return (
    <BaseHeader
      {...props}
      leftElement={<HeaderCloseIconButton onClose={onCloseClick} />}
      rightElement={<></>}
    />
  );
}
