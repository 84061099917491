import { BaseHeader, BaseHeaderProps } from '../base-header';
import { HeaderHelpChip } from '../components';

export type HeaderWithHelpProps = Omit<BaseHeaderProps, 'rightElement'> & {
  onHelpClick?: () => void;
};

export function HeaderWithHelp({
  onHelpClick,
  ...props
}: Readonly<HeaderWithHelpProps>) {
  return (
    <BaseHeader
      {...props}
      leftElement={null}
      rightElement={<HeaderHelpChip onClick={onHelpClick} />}
    />
  );
}
