'use client';

import { useLayoutEffect, useState } from 'react';

import {
  SigninForm,
  WelcomeBackForm,
} from '@/features/authentication/signin/components';

import { HeaderHelpChip } from '@/ui/components/header/components';
import { HeaderWithLogo } from '@/ui/components/header/variants';
import { Page, PageSubtitle, PageTitle } from '@/ui/components/page';

import { useDeviceDetect } from '@/shared/hooks';
import { useAccountLog } from '@/shared/hooks/use-accounts-log';
import { ROUTES } from '@/shared/routes';
import { Profile } from '@/shared/types';

import { LOCAL_STORAGE_KEYS } from '@/lib/constants';
import { Link } from '@/lib/navigation';
import { cn, getLocalStorageItem } from '@/lib/utils';

function WelcomeBackPageContent({
  profile,
}: Readonly<{ profile: Partial<Profile> }>) {
  return <WelcomeBackForm data={profile} />;
}

function SigninPageContent() {
  return (
    <>
      <PageTitle
        align="left"
        text="Log in"
        subtitle={
          <PageSubtitle align="left" color="content-subtext-default">
            Continue using your mobile number.
            <br />
            New to MonieWorld?{' '}
            <Link
              className="inline-block text-moniepoint-primary-l7"
              href={ROUTES.SIGN_UP.INDEX}
            >
              Sign up
            </Link>
          </PageSubtitle>
        }
      />

      <SigninForm />
    </>
  );
}

interface ProfileKey {
  id: string;
}

type ProfileTypes = Partial<Profile> | null | undefined;

export default function SigninPage() {
  const { isTabletOrMobile, isTablet, isMobile } = useDeviceDetect();
  const { getProfile } = useAccountLog();
  const [profile, setProfile] = useState<ProfileTypes>(undefined);

  useLayoutEffect(() => {
    // this operation is synchronous and happens before the next paint so we do not need a loader
    const storedUser = getLocalStorageItem(
      LOCAL_STORAGE_KEYS.CURRENT_USER,
    ) as ProfileKey | null;

    setProfile(getProfile(storedUser?.id) ?? null);
  }, []);

  if (profile === undefined) {
    return null;
  }

  const isReturningUser = !!profile?.firstName && !!profile?.phone;

  return (
    <>
      <HeaderWithLogo rightElement={<HeaderHelpChip />} />
      <Page
        container={isMobile ? 'sm' : undefined}
        className={cn(
          'flex-grow justify-start gap-units-unit40 md:items-center',
          {
            'md:my-units-unit80': isReturningUser && !isTabletOrMobile,
            'md:my-0': isTablet && isReturningUser,
          },
        )}
      >
        {isReturningUser ? (
          <WelcomeBackPageContent profile={profile} />
        ) : (
          <SigninPageContent />
        )}
      </Page>
    </>
  );
}
